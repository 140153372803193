<template>
  <div id="main-wrapper" class="inner-pages forex-page indices-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li><router-link to="/forex">Forex</router-link></li>
            <li class="active">
              <router-link to="/indices">Indices</router-link>
            </li>
            <li><router-link to="/commodities">Commodities</router-link></li>
            <li><router-link to="/stocks">Stocks</router-link></li>
            <li><router-link to="/crypto">Cryptocurrencies</router-link></li>
          </ul>
        </div>
      </div>
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>INDICES</h2>
        <p>Grow your trading account by trading global indices</p>
      </div>
    </div>
    <div class="section strip-details">
      <div class="wrapper2">
        <div class="strip-info wow fadeIn">
          <h3>min. <span>$0.74</span> Commission</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.3s">
          <h3>min. execution <span>10ms</span></h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.6s">
          <h3>min. <span>0.0</span> Spread</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.9s">
          <h3><span>10+</span> Indices</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="1.2s">
          <h3>Up to <span>20x</span> Leverage</h3>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="title-holder t-left wow fadeIn">
          <h3>Indices Contract <strong>Specifications</strong></h3>
          <p>
            More flexible trading conditions (increased leverage) may apply in
            case of clients classified as Professional. Please log in to your
            account and check eligibility for Professional Client,
            classification, or write to us at
            <a href="mailto:support@aaafx.com">support@aaafx.com</a>
          </p>
        </div>
        <div class="table-holder home-table wow fadeIn">
          <div class="search-holder">
            <input
              type="text"
              name=""
              value=""
              placeholder="Search Instrument"
            />
          </div>
          <table>
            <tr>
              <th>Instrument</th>
             <!--  <th>Leverage</th> -->
              <th>Bid</th>
              <th>Ask</th>
              <th class="">Spread</th>
              <th class="">Contract Size</th>
              <th class="">Min. Trade Size</th>
              <th class="">Tick Value</th>
              <th>Trading Hours</th>
            </tr>
            <tr
              v-for="(spreads, key, index) in get_currencies"
              :key="key"
              :index="index"
              v-show="index < page * limit && index >= (page - 1) * limit"
            >
              <td>
                <!--  <img src="assets/images/bitcoin-ico.webp" alt="">  -->{{
                  key
                }}
              </td>
             <!--  <td><span class="lev">1-30X</span></td> -->
              <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
							<td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.buy_increase_staus == 1,
                      't-red': spreads.buy_increase_staus == 0,
                    },
                  ]"
                  >{{ spreads.B }}</span
                >
              </td>
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.sell_increase_staus == 1,
                      't-red': spreads.sell_increase_staus == 0,
                    },
                  ]"
                  >{{ spreads.A }}</span
                >
              </td>
              <td class="">{{ spreads.SPREAD }}</td>
              <td class="">{{ spreads.other.Contract_Size }}</td>
              <td class="">{{ spreads.other.Min_Trade_Size }}</td>
              <td class="">{{ spreads.other.Tick_Value }}</td>
              <td class="">7:00 am - 12:00pm</td>
            </tr>
          </table>
          <div class="clearfix links">
            <!-- <a href="#" class="t-right see-link f-right"> Next</a> -->
            <pagination
              :totalRecords="Object.keys(this.get_currencies).length"
              :limit="limit"
              :pageNo="page"
            />
          </div>
        </div>

        <div class="section-small">
          <div class="accordion-holder t-left">
            <h3 v-on:click="Indices ^= true" :class="Indices?'active':''">What is Indices CFD Trading?</h3>
            <div class="content" v-show="Indices">
              <p>
                An index represents a separate portfolio with an amalgamation of
                various companies’ profiles which helps in mitigating the risk
                profile of a client. <br />Indices trading relieves a trader of
                the large amount of time to be spent in analysing a single
                stock. Indices can be of various types, geography based (like
                Spain Index, German Index, Hong Kong Index) and sector based
                (like NASDAQ).
              </p>
            </div>
            <h3 v-on:click="work ^= true" :class="work?'active':''">How does it work?</h3>
            <div class="content" v-show="work">
              <p>
                The volatility in Stock Indices is driven by political and economic factors and not just the performance of individual stocks. Traders can gain profits by speculating on the movement of stock indices in upwards or downwards direction. The trader does not need to buy all of individual stocks listed on Dow Jones, for example. He can simply trade the Dow Jones CFD. Trading indices is a great way of diversifying your portfolio with a small capital outlay. Traders generally rely on economic performance in regions (for country-based indices) or also the overall performance of the sector (for sector based indices) to assess whether they should go long or short on the index. Index itself is just a number, but the beauty of this product lies in the ability to invest overall in all individual shares of the Index, with a much smaller capital.
              </p>
            </div>
          </div>
        </div>

        <div class="section account-types">
          <div class="title-holder t-left wow fadeIn">
            <h3>Account <strong>Types</strong></h3>
          </div>
          <div class="table-holder home-table acct-type wow fadeIn">
            <table>
              <tr>
                <th>&nbsp;</th>
                <th>ECN</th>
                <th>ECN <span>plus</span></th>
                <th>ECN <span>zulu</span></th>
              </tr>
              <tr>
                <td>Deposit (min.)</td>
                <td>$10</td>
                <td>$10,000</td>
                <td>$300</td>
              </tr>
              <tr>
                <td>Commission (per $100k)</td>
                <td>$0.99</td>
                <td>$0.74</td>
                <td>$10</td>
              </tr>
              <tr>
                <td>Spread (min.)</td>
                <td>0.0</td>
                <td>0.0</td>
                <td>0.0</td>
              </tr>
            </table>
            <div class="clearfix links">
              <a
                href="account-types"
                target="_blank"
                class="t-right see-link f-right"
              >
                Compare Account Types</a
              >
            </div>
          </div>
        </div>

        <div class="section trading-plat">
          <div class="info-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>
                Our Online <strong>Trading <br />Platforms</strong>
              </h3>
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> Desktop</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> Web Trader</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> Android</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img src="assets/images/plat-ico4.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> iOS</a
              >
            </div>
            <div class="btn-holder">
              <router-link to="/compare-platform" class="btn btn-bluey"
                >LEARN MORE</router-link>
            </div>
          </div>
          <div class="links-holder wow fadeInRight">
            <div class="link-holder col">
              <img src="assets/images/plat1.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
            </div>
            <div class="link-holder col">
              <img src="assets/images/plat2.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
            </div>
            <div class="link-holder col">
              <img src="assets/images/plat3.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
            </div>
            <!-- <div class="link-holder col">
              <img src="assets/images/plat4.webp" alt="" />
            </div> -->
          </div>
        </div>

        <div class="section nega-sec">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>Negative Balance <strong>Protection</strong></h3>
              <p class="sub">
                Reliable protection of your funds on AAAFx Accounts
              </p>
              <br />
              <p>
                We always endeavor to work towards the best interest of our
                clients. While trading in volatile times, if your account goes
                into negative, we have got your back! We will reset your
                negative balance to zero, so that you do not bear any extra
                loss. You can deposit and start trading again. Hence, you cannot
                lose more than what you deposit.
              </p>
              <div class="btn-holder">
                <a
                  :href="siteurl2"
                  target = "_blank"
                  class="btn btn-yellow"
                  >OPEN LIVE ACCOUNT</a
                >
                <a
                  :href="siteurl2"
                  target = "_blank"
                  class="btn btn-blue"
                  >OPEN DEMO ACCOUNT</a
                >
              </div>
            </div>
          </div>
          <div class="img-holder t-center wow fadeInRight">
            <img src="assets/images/nega-img.webp" alt="AAAFx" title="AAAFx" width="579" height="433" class="fluid-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Const } from "../plugins/vue-provider-cache/const.mod";
import pagination from "@/components/shared/pagination.vue";
export default {
  name: "forex",
  data() {
    return {
      page: 1,
      limit: 8,
      Indices: true,
      work: false,
      siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
    };
  },
  components: {
    pagination: pagination,
  },
  computed: {
    get_currencies() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getIndicesAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
  },
};
</script>
